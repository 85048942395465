$(document).ready(function() {

	$input = $('*[data-behavior="autocomplete"]')

  var options = {
  	getValue: "word",
    cssClasses: 'col',
    requestDelay: 250,
    url: function(phrase) {
      return "/searches/autosuggest?q=" + phrase;
    },

    list: {
			onChooseEvent: function() {
				var searchLink = $input.getSelectedItemData().link;
				window.location = searchLink;
			}
		}
  };

  $input.easyAutocomplete(options);
})
